@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

html {
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;